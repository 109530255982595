import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import FavIcon from "../../../../assets/images/logo.webp";
import TelegramIcon from "../../../../assets/images/telegramIcon.svg";
import WhatsappIcon from "../../../../assets/images/whatsappIcon.svg";
import logo from "../../../../assets/images/logo.webp";
import { useSelector } from "react-redux";

const SupportCustomer = () => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className="container p-0">
          <div className="support-header">
            <div className="favicon">
              <img src={appDetails?.LOGO_URL || logo} alt="Logo" />
            </div>
            <div className="support-heading">
              <h4>Namastey!</h4>
              <p>
                We are always here to help. 24/7 Call and Chat Support is
                Available Now!
              </p>
            </div>
            <div className="chat-call-support">
              <div className="chat-box">
                <a href={`tel://${appDetails?.PHONE}`}>
                  <h2 class="sectionTitle border-0">Call Support</h2>
                </a>
              </div>
              <div className="chat-box">
                <h2 class="sectionTitle border-0">Chat Support</h2>
                <ul>
                  <li>
                    <a
                      href={
                        appDetails.TELEGRAM != "" ? appDetails.TELEGRAM : "#"
                      }
                      target={appDetails.TELEGRAM != "" ? "_blank" : ""}
                    >
                      <img src={TelegramIcon} alt="telegram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={
                        appDetails?.WHATS_APP_URL != ""
                          ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                          : "#"
                      }
                      target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                    >
                      <img src={WhatsappIcon} alt="whatsapp" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default SupportCustomer;
